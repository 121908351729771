import axios from "axios";
import config from "../../config";

const handleApiRequest = async (validData) => {
  console.log(validData);
  try {
    const url = config.apiUrl + "/request_search_save";
    await axios.post(url, validData);
  } catch (error) {
    throw error;
  }
};

export default handleApiRequest;
