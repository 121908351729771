import React from "react";
import Typography from "@mui/material/Typography";
import "./notfoundpage.css";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">
        Desculpe, a página procurada não pode ser encontrada.
      </Typography>
    </div>
  );
};

export default NotFoundPage;
