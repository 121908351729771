import jwt_decode from "jwt-decode";

function isLoggedIn() {
  const token = localStorage.getItem("access_token");
  if (token) {
    try {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000; // Divide by 1000 to get seconds

      if (decoded.exp < currentTime) {
        // Token has expired
        return false;
      } else {
        // Token is valid
        return true;
      }
    } catch (error) {
      // Error decoding token
      return false;
    }
  } else {
    // No token found
    return false;
  }
}
export default isLoggedIn;
