import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import { Container, Paper } from "@mui/material";
import theme from "../../themes/theme";

function AvailableChart({ data }) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  ChartJS.defaults.font.family = theme.typography.fontFamily;

  const config = {
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "right",
        },
      },
    },
  };

  return (
    <Container
      component={Paper}
      sx={{ height: "412px", borderRadius: "6px", padding: "2rem" }}
    >
      <Pie data={data} options={config.options} />
    </Container>
  );
}

export default AvailableChart;
