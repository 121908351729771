const settings = {
  local: {
    apiUrl: "http://localhost:8000/api",
  },
  docker: {
    apiUrl: "https://bookclub.uno/api",
  },
};

export default settings[process.env.REACT_APP_APP_ENV || "local"];
