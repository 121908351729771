import React from "react";
import { useNavigate } from "react-router-dom";
import style from "./newlandingpage.module.css";
import Image from "../../assets/images/landingpage/_a621465f-5a7d-4ac7-81cf-49af99b7eb941.jpg";
import Image2 from "../../assets/images/landingpage/_fa9e5f65-6fd5-4496-a2dc-a7a33a9bda9e.jpg";
import Image3 from "../../assets/images/landingpage/_cd8f220f-6a05-4e01-8cd3-963445156ef8.jpg";
import Image4 from "../../assets/images/landingpage/_e3ac14a8-e41e-49a7-aaf8-6dcee8507a79.jpg";

function NewLandingPage() {
  let navigate = useNavigate();

  const handleSignUpPress = () => {
    navigate("/signup");
  };

  const handleLogInPress = () => {
    navigate("/signin");
  };

  return (
    <div className={style.page_container}>
      <div className={style.app_bar}>
        <div className={style.logo_container}>autohunt</div>
        <div className={style.app_bar_buttons_container}>
          <button
            type="button"
            onClick={handleSignUpPress}
            className={style.app_bar_button_signup}
          >
            Cadastre-se
          </button>
          <button
            type="button"
            onClick={handleLogInPress}
            className={style.app_bar_button_signin}
          >
            Log In
          </button>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.header_container}>
          <div className={style.header_title}>
            Explore o Poder do Mercado de Veículos na Era Digital
          </div>
          <div className={style.header_subtitle}>
            Obtenha insights e estatísticas essenciais para maximizar o
            desempenho em operações de compra e venda de veículos.
          </div>
          <button
            type="button"
            onClick={handleSignUpPress}
            className={style.signup_button}
          >
            Cadastre-se
          </button>
        </div>
        <div className={style.main_image_container}>
          <img src={Image} className={style.main_image} />
        </div>
        <div className={style.secondary_section_header_container}>
          <div className={style.secondary_section_header_title}>
            Simplificando o Processo de Compra de Carros para Você
          </div>
          <div className={style.secondary_section_header_subtitle}>
            Nosso site oferece uma análise abrangente do mercado de carros
            online. Disponibilizamos estatísticas e insights valiosos para
            ajudá-lo a tomar decisões informadas. Desde o tempo estimado para
            vender um veículo até os preços recomendados, cobrimos todas as suas
            necessidades.
          </div>
        </div>
        <div className={style.third_section_container}>
          <div className={style.third_section_block_container}>
            <img className={style.third_section_image} src={Image2} />
            <div className={style.third_section_title}>
              Maximizando Margens com Preços Estratégicos
            </div>
            <div className={style.third_section_subtitle}>
              Alcance lucros significativos ao ajustar seus preços de acordo com
              análises precisas, moldando uma estratégia de compra e venda mais
              rentável.
            </div>
          </div>
          <div className={style.third_section_block_container}>
            <img src={Image3} className={style.third_section_image} />
            <div className={style.third_section_title}>
              O Tempo Como Aliado nas Transações
            </div>
            <div className={style.third_section_subtitle}>
              Aproveite o poder do conhecimento do tempo estimado de venda para
              realizar transações de forma eficaz, otimizando seu fluxo de
              caixa.{" "}
            </div>
          </div>
          <div className={style.third_section_block_container}>
            <img className={style.third_section_image} src={Image4} />
            <div className={style.third_section_title}>
              Visão de Futuro no Mercado Automotivo
            </div>
            <div className={style.third_section_subtitle}>
              Antecipe-se às mudanças do mercado, utilizando dados históricos
              para construir uma visão de futuro que guie suas operações com
              sucesso.{" "}
            </div>
          </div>
        </div>
        <div className={style.footer_container}>
          <button
            type="button"
            onClick={handleSignUpPress}
            className={style.signup_button}
          >
            Cadastre-se
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewLandingPage;
