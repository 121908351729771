import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RandomImage from "../../components/images/RandomImages";
import axios from "axios";
import config from "../../config";
import logoImage from "../../assets/autohunt_logos/autohunt_logo_big_green_black.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Auto Hunt
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignInSide() {
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const params = new URLSearchParams({
      grant_type: "",
      username: data.get("email"),
      password: data.get("password"),
      scope: "",
      client_id: "",
      client_secret: "",
    });
    try {
      const response = await axios.post(
        config.apiUrl + "/login",
        params.toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const token = response.data.access_token;
      const token_type = response.data.token_type;
      // Store the token in local storage
      localStorage.setItem("access_token", token);
      localStorage.setItem("token_type", token_type);

      // Redirect the user to the protected route
      window.location.href = "/dashboard";
    } catch (error) {
      setError("Usuário e/ou senha inválido(s)");
    }
  };

  const handleLogoPress = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100%",
        boxSizing: "border-box",
        overflow: { xs: "auto", sm: "auto" },
      }}
    >
      <Grid item xs={false} sm={4} md={7}>
        <RandomImage />
      </Grid>
      <Grid
        container
        display={"flex"}
        justifyContent="space-evenly"
        alignItems={"center"}
        direction={"column"}
        paddingY={"20px"}
        rowSpacing={2}
        xs={12}
        sm={8}
        md={5}
      >
        <Grid
          item
          sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "center",
          }}
        >
          <img
            src={logoImage}
            onClick={handleLogoPress}
            style={{
              height: "auto",
              width: "80%",
              cursor: "pointer",
            }}
          />
        </Grid>
        <Grid item marginY={{ xs: "20px" }}>
          <Box
            sx={{
              padding: "20px",
              borderRadius: "20px",
              backgroundColor: "white",
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Typography
              sx={{ alignSelf: "flex-start" }}
              component="h1"
              variant="h5"
            >
              Acessar Conta
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Endereço de e-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {error && ( // Render the error message if it exists
                <Typography
                  component="h2"
                  variant="h6"
                  color="error"
                  fontSize="small"
                >
                  {error}
                </Typography>
              )}
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Lembrar-me"
              />
              <Button
                color="secondary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Esqueci minha senha
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    Cadastre-se
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
