import React from "react";

function RandomImage() {
  const images = [];
  const imageContext = require.context(
    "../../assets/images/background", // Path to the target directory
    true, // Include subdirectories
    /\.(jpg|jpeg|png|gif)$/ // Match only image files
  );

  imageContext.keys().forEach((key) => {
    images.push(imageContext(key));
  });
  const randomIndex = Math.floor(Math.random() * images.length);
  const randomImage = images[randomIndex];
  return (
    <img
      src={randomImage}
      alt="Random Image"
      style={{
        objectFit: "cover",
        height: "100%",
        width: "100%",
      }}
    />
  );
}

export default RandomImage;
