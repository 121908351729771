import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import config from "../../config";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Auto Hunt
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUpPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nameParam = queryParams.get("name") || "";
  const surnameParam = queryParams.get("surname") || "";
  const emailParam = queryParams.get("email") || "";

  const [firstName, setFirstName] = useState(nameParam);
  const [email, setEmail] = useState(emailParam);
  const [lastName, setLastName] = useState(surnameParam);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    let isError = false;

    if (firstName.length === 0) {
      setFirstNameError("Nome é obrigatório");
      isError = true;
    } else {
      setFirstNameError("");
    }

    if (lastName.length === 0) {
      setLastNameError("Sobrenome é obrigatório");
      isError = true;
    } else {
      setLastNameError("");
    }

    if (email.length === 0) {
      setEmailError("E-mail é obrigatório");
      isError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Endereço de e-mail inválido");
      isError = true;
    } else {
      setEmailError("");
    }

    if (password.length === 0) {
      setPasswordError("Senha é obrigatório");
      isError = true;
    } else if (password.length < 8) {
      setPasswordError("A senha deve conter ao mínimo 8 caracteres");
      isError = true;
    } else {
      setPasswordError("");
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Por favor confirme sua senha");
      isError = true;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("As senhas são diferentes");
      isError = true;
    } else {
      setConfirmPasswordError("");
    }

    return isError;
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      const response = await axios
        .post(config.apiUrl + "/signup", {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
        })
        .then(() => navigate("/"))
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrorMessage("E-mail já cadastrado."); // Set the error message
          } else {
            console.error(error);
          }
        });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        paddingInline: "1rem",
      }}
    >
      <Container
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          paddingBlock: "20px",
        }}
        component="main"
        maxWidth="xs"
      >
        <Box>
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
          <Typography component="h1" variant="h5">
            Cadastre-se
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Nome"
                  autoFocus
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  error={firstNameError.length > 0}
                  helperText={firstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Sobrenome"
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  error={lastNameError.length > 0}
                  helperText={lastNameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Endereço de e-mail"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={emailError.length > 0}
                  helperText={emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setPassword(event.target.value)}
                  error={passwordError.length > 0}
                  helperText={passwordError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirme sua senha"
                  type="password"
                  id="confirmPassword"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  error={confirmPasswordError.length > 0}
                  helperText={confirmPasswordError}
                />
              </Grid>
            </Grid>
            <Button
              color="secondary"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Cadastrar
            </Button>
            {errorMessage && (
              <Typography variant="body2" color="error" align="center">
                {errorMessage}
              </Typography>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Já possui uma conta? Faça login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </div>
  );
}
