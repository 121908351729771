import React from "react";
import Typography from "@mui/material/Typography";
import style from "./indevelopment.module.css";

const InDevelopmentPage = () => {
  return (
    <div className={style["page-container"]}>
      <Typography variant="h3">Página em desenvolvimento!</Typography>
    </div>
  );
};

export default InDevelopmentPage;
