import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { Button, Typography, Box } from "@mui/material";

function ProfilePage() {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const fetchUser = async () => {
      const response = await axios.get(config.apiUrl + "/get-user-details", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return response.data;
    };

    fetchUser().then((user) => {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setRole(user.role);
      setStripeCustomerId(user.stripe_customer_id);
    });
  }, []);

  const handleManageSubscription = () => {
    const access_token = localStorage.getItem("access_token");
    axios
      .post(
        config.apiUrl + "/create-customer-portal-session",
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        window.location = response.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const plans = {
    admin: "Admin",
    test: "Teste",
    free: "Gratuito",
    basic: "Pessoal",
    premium: "Essencial",
    enterprise: "Enterprise",
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <Typography variant="h3" sx={{ mb: 2 }}>
        {firstName} {lastName}
      </Typography>
      <div style={{ marginTop: "50px" }}></div>
      <Typography variant="h5" sx={{ mb: 2 }}>
        <span style={{ fontWeight: "bold" }}>E-mail:</span>{" "}
        <span style={{ fontStyle: "italic" }}>{email}</span>
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        <span style={{ fontWeight: "bold" }}>Plano Atual:</span>{" "}
        <span style={{ fontStyle: "italic" }}>{plans[role]}</span>
      </Typography>
      {/* <Button
        color="error"
        variant="outlined"
        sx={{ display: stripeCustomerId ? "block" : "none" }}
        onClick={handleManageSubscription}
      >
        Gerenciar Assinatura
      </Button> */}
    </Box>
  );
}

export default ProfilePage;
