import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

function TwoColumnList({ title, value, linkTo, noWrap }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} overflow={"hidden"}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid item xs={6} overflow={"hidden"}>
        {linkTo ? (
          <Typography
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "black",
              ":hover": { color: "blue" },
            }}
            component={"a"}
            href={value}
            variant="subtitle2"
            noWrap
          >
            {value}
          </Typography>
        ) : (
          <Typography
            noWrap={isHovered ? false : noWrap}
            variant="subtitle2"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default TwoColumnList;
