import React from "react";
import { Line } from "react-chartjs-2";
import jStat from "jstat";
import { Paper, Container } from "@mui/material";
import { Chart, Filler } from "chart.js";
import theme from "../../themes/theme";

const NormalDistributionChart = ({ title, selectedValue, data }) => {
  Chart.register(Filler);

  const mean = jStat.mean(data);
  const stdDev = jStat.stdev(data);

  const xValues = [];
  const allValues = [];
  const selectedValues = [];

  const desiredDataPoints = 70; // Adjust this based on your preference
  const rangeMultiplier = 4; // Adjust this based on the range you want to cover

  const step = (rangeMultiplier * 2 * stdDev) / desiredDataPoints;

  for (let x = mean - 4 * stdDev; x <= mean + 4 * stdDev; x += step) {
    xValues.push(x);
    if (x <= selectedValue) {
      allValues.push(jStat.normal.pdf(x, mean, stdDev));
      selectedValues.push(null); // Placeholder for the second dataset
    } else {
      allValues.push(jStat.normal.pdf(x, mean, stdDev));
      selectedValues.push(jStat.normal.pdf(x, mean, stdDev));
    }
  }

  const resultArray = Array.from(
    { length: selectedValues.length },
    (_, index) =>
      index === selectedValues.findIndex((element) => element !== null)
        ? selectedValues[index]
        : null
  );

  const filteredData = {
    labels: xValues,
    datasets: [
      selectedValue
        ? {
            data: selectedValues,
            pointStyle: false,
            borderColor: theme.palette.success.main,
            backgroundColor: theme.palette.success.dark,
            fill: true,
            order: 2,
          }
        : {},
      {
        data: allValues,
        pointStyle: false,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
        fill: true,
        order: 3,
      },
      selectedValue
        ? {
            data: resultArray,
            backgroundColor: "gold",
            borderColor: "black",
            fill: true,
            pointRadius: 3,
            order: 1,
          }
        : {},
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: {
      easing: "easeInOutQuad",
    },
    events: [],
    scales: {
      x: {
        type: "linear",
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: title ? true : false,
        text: title,
        font: {
          size: 20,
        },
        padding: {
          bottom: 20,
        },
      },
    },
  };

  return (
    <Container
      component={Paper}
      sx={{ height: "412px", borderRadius: "6px", padding: "2rem" }}
    >
      <Line data={filteredData} options={options} />
    </Container>
  );
};

export default NormalDistributionChart;
