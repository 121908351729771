import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import YearChart from "../../components/charts/YearChart";
import AvailableChart from "../../components/charts/AvailableChart";
import { useParams } from "react-router-dom";
import FoundVehiclesTable from "../../components/table/FoundVehiclesTable";
import TwoColumnTable from "../../components/table/TwoColumnTable";
import {
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../themes/theme";
import PriceKmChart from "../../components/charts/PriceKmChart";
import NormalDistributionChart from "../../components/charts/NormalDistributionChart";

function SubscriptionPage() {
  const [pageTitle, setPageTitle] = useState("");
  const [detailsData, setDetailsData] = useState([]);
  const [marketStatsData, setMarketStatsData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [yearChartData, setYearChartData] = useState({
    datasets: [],
  });
  const [availableChartData, setAvailableChartData] = useState({
    datasets: [],
  });
  const [priceKmChartData, setPriceKmChartData] = useState({
    datasets: [],
  });
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState({
    filters: { make: "", model: "" },
    vehicles_details: [],
  });
  const [originalData, setOriginalData] = useState({
    filters: { make: "", model: "" },
    vehicles_details: [],
  });
  const [priceNormalDistData, setPriceNormalDistData] = useState([]);
  const [kmNormalDistData, setKmNormalDistData] = useState([]);
  const [correctDataCheck, setCorrectDataCheck] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const access_token = localStorage.getItem("access_token");
      const result = await axios.get(config.apiUrl + `/subscriptions/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setOriginalData(result.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const correctedData = correctData(originalData);
    setData(correctedData);
  }, [correctDataCheck, originalData]);

  useEffect(() => {
    const vehiclesDetails = data.vehicles_details;

    const vehicle = vehiclesDetails.find(
      (item) => item.unique_id == selectedRow
    );
    setSelectedVehicle(vehicle);
  }, [selectedRow, data]);

  useEffect(() => {
    const parsedPageTitle = parsePageTitle(data);
    setPageTitle(parsedPageTitle);

    const filteredData = filterData(data);
    setTableData(filteredData);

    const filteredDetailsData = filterDetailsData(data);
    setDetailsData(filteredDetailsData);

    const filteredMarketStatsData = filterMarketStatsData(data);
    setMarketStatsData(filteredMarketStatsData);

    const filteredYearChartData = filterYearChartData(data);
    setYearChartData(filteredYearChartData);

    const filteredAvailableChartData = filterAvailableChartData(data);
    setAvailableChartData(filteredAvailableChartData);

    const filteredPriceKmChartData = filterPriceKmChartData(data);
    setPriceKmChartData(filteredPriceKmChartData);

    const filteredPriceNormalDistData = filterPriceNormalDistData(data);
    setPriceNormalDistData(filteredPriceNormalDistData);
    const filteredKmNormalDistData = filterKmNormalDistData(data);
    setKmNormalDistData(filteredKmNormalDistData);
  }, [data, selectedVehicle]);

  const handleFavoriteClick = async (id, newValue) => {
    const updatedData = {
      ...data,
      vehicles_details: data.vehicles_details.map((vehicle) => {
        if (vehicle.unique_id == id) {
          return {
            ...vehicle,
            vehicle_metadata: {
              ...vehicle.vehicle_metadata,
              is_favorite: newValue,
            },
          };
        }
        return vehicle;
      }),
    };

    setData(updatedData);
  };

  const handleNotificationClick = async (id, newValue) => {
    const updatedData = {
      ...data,
      vehicles_details: data.vehicles_details.map((vehicle) => {
        if (vehicle.unique_id == id) {
          return {
            ...vehicle,
            vehicle_metadata: {
              ...vehicle.vehicle_metadata,
              notification: newValue,
            },
          };
        }
        return vehicle;
      }),
    };

    setData(updatedData);
  };

  function correctData() {
    if (!correctDataCheck) {
      return originalData;
    }

    function parseVehicleData(inputObject) {
      const parsedObject = { ...inputObject };

      if (parsedObject.vehicles_details) {
        parsedObject.vehicles_details = parsedObject.vehicles_details.map(
          (vehicle) => {
            const parsedVehicle = { ...vehicle }; // Create a deep copy of the vehicle object
            if (
              parsedVehicle.km >= 1 &&
              parsedVehicle.km <= 500 &&
              parsedVehicle.price <= 500000
            ) {
              parsedVehicle.km *= 1000;
            }
            if (parsedVehicle.price >= 20000000) {
              parsedVehicle.price /= 1000;
            }
            return parsedVehicle;
          }
        );
      }

      return parsedObject;
    }

    return parseVehicleData(originalData);
  }

  function filterPriceNormalDistData(data) {
    return data.vehicles_details.map((item) => {
      return item.price;
    });
  }

  function filterKmNormalDistData(data) {
    return data.vehicles_details.map((item) => {
      return item.km;
    });
  }

  const handleRowSelect = (value) => {
    setSelectedRow(value);
  };

  function filterPriceKmChartData(data) {
    const availableVehiclesData = [];
    const unavailableVehiclesData = [];
    data.vehicles_details.map((item) => {
      const dataPoint = {
        x: item.km,
        y: item.price,
        id: item.unique_id,
      };
      if (item.vehicle_metadata.is_available) {
        availableVehiclesData.push(dataPoint);
      } else {
        unavailableVehiclesData.push(dataPoint);
      }
    });
    const availableBackgroundColors = availableVehiclesData.map((item) => {
      return item.id == selectedRow ? "gold" : theme.palette.success.light;
    });
    const unavailableBackgroundColors = unavailableVehiclesData.map((item) => {
      return item.id == selectedRow ? "gold" : theme.palette.error.light;
    });

    const filteredPriceKmData = {
      datasets: [
        {
          label: "Disponível",
          backgroundColor: availableBackgroundColors,
          data: availableVehiclesData,
        },
        {
          label: "Vendido",
          backgroundColor: unavailableBackgroundColors,
          data: unavailableVehiclesData,
        },
      ],
    };
    return filteredPriceKmData;
  }

  function capitalizeFirstLetterOfEachWord(str) {
    if (!str) return ""; // Handle the case of an empty string or null input

    const words = str.split(" "); // Split the string into an array of words
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase(); // Get the first letter and capitalize it
      const restOfWord = word.slice(1).toLowerCase(); // Get the rest of the word (excluding the first letter)
      return firstLetter + restOfWord;
    });

    return capitalizedWords.join(" "); // Join the words back into a single string
  }

  const parsePageTitle = (data) => {
    const make = capitalizeFirstLetterOfEachWord(data.filters.make ?? "");
    const model = capitalizeFirstLetterOfEachWord(data.filters.model ?? "");
    return `${make} ${model}`;
  };

  function filterMarketStatsData(data) {
    const marketStatsData = calculateMarketStatsData(data);
    const formattedData = [];

    // Extracting values from the marketStatsData object
    const {
      totalVehiclesFound,
      vehiclesAvailable,
      averagePrice,
      averageKm,
      rotativity,
    } = marketStatsData;

    // Adding Total Vehicles Found to the formattedData list
    if (totalVehiclesFound !== undefined) {
      formattedData.push({
        key: "Total de veículos encontrados",
        value: totalVehiclesFound,
      });
    }

    // Adding Vehicles Available to the formattedData list
    if (vehiclesAvailable !== undefined) {
      formattedData.push({
        key: "Veículos disponíveis",
        value: vehiclesAvailable,
      });
    }
    // Adding Average Price to the formattedData list
    if (averagePrice !== undefined) {
      formattedData.push({ key: "Preço médio", value: averagePrice });
    }
    // Adding Average Kilometer to the formattedData list
    if (averageKm !== undefined) {
      formattedData.push({ key: "Quilometragem média", value: averageKm });
    }

    // Adding Rotativity (if available) to the formattedData list
    if (rotativity !== undefined) {
      formattedData.push({ key: "Rotatividade média", value: rotativity });
    }

    return formattedData;
  }
  const calculateMarketStatsData = (data) => {
    const totalVehiclesFound = (data) => {
      return data.vehicles_details.length;
    };

    const vehiclesAvailable = (data) => {
      const availableVehicles = data.vehicles_details.filter(
        (item) => item.vehicle_metadata.is_available
      );
      return availableVehicles.length;
    };

    function calculateAverageWithoutOutliers(arr) {
      // Calculate the interquartile range (IQR)
      const sortedArr = arr.slice().sort((a, b) => a - b);
      const q1 = sortedArr[Math.floor(0.25 * sortedArr.length)];
      const q3 = sortedArr[Math.ceil(0.75 * sortedArr.length)];
      const iqr = q3 - q1;

      // Define the lower and upper bounds for outliers
      const lowerBound = q1 - 1.5 * iqr;
      const upperBound = q3 + 1.5 * iqr;

      // Filter out the outliers
      const filteredArr = arr.filter(
        (value) => value >= lowerBound && value <= upperBound
      );

      // Calculate the average of the filtered values
      const sum = filteredArr.reduce(
        (total, currentValue) => total + currentValue,
        0
      );
      const average = sum / filteredArr.length;

      return Math.round(average);
    }

    const calculateAverage = (arr) => {
      if (!Array.isArray(arr) || arr.length === 0) {
        return 0; // Handle edge case of empty array or non-array input
      }

      const sum = arr.reduce((total, currentValue) => total + currentValue, 0);
      const average = sum / arr.length;
      return average;
    };

    const averagePrice = (data) => {
      const prices = data.vehicles_details
        .map((item) => parseFloat(item.price))
        .filter((price) => !isNaN(price));

      return calculateAverageWithoutOutliers(prices);
    };

    const fipePrice = (data) => {
      const vehicle = data.vehicle_metadata.vehicles_details[0];
      return (vehicle.price * vehicle.fipe_percent) / 100;
    };

    const averageKm = (data) => {
      const kms = data.vehicles_details
        .map((item) => parseFloat(item.km))
        .filter((km) => !isNaN(km));
      return calculateAverageWithoutOutliers(kms);
    };

    const rotativity = (data) => {
      // Implement the functionality for rotativity if needed
      // For example, you can calculate the percentage of sold vehicles within a specific period.
      // This part is left empty as it requires specific business logic.
    };

    const formatValue = (value) => {
      if (value === null || isNaN(value)) {
        return ""; // Return an empty string if the value is null or NaN
      }
      return value;
    };

    const finalData = {};

    const totalVehiclesFoundValue = formatValue(totalVehiclesFound(data));
    const vehiclesAvailableValue = formatValue(vehiclesAvailable(data));
    const averagePriceValue = formatValue(averagePrice(data));
    const averageKmValue = formatValue(averageKm(data));

    if (totalVehiclesFoundValue !== "") {
      finalData.totalVehiclesFound = totalVehiclesFoundValue;
    }

    if (vehiclesAvailableValue !== "") {
      finalData.vehiclesAvailable = vehiclesAvailableValue;
    }

    if (averagePriceValue !== "") {
      finalData.averagePrice = averagePriceValue;
    }

    if (averageKmValue !== "") {
      finalData.averageKm = averageKmValue;
    }
    return finalData;
  };

  const filterData = (data) => {
    const vehicles_details = data.vehicles_details;
    return vehicles_details.map((item) => {
      // Convert the date from "2023-07-20T13:43:26.207Z" to a JavaScript Date object
      const dateObject = item.vehicle_metadata.date_first_scraped
        ? new Date(item.vehicle_metadata.date_first_scraped.$date)
        : null;

      // Get day, month, and year components
      const day = dateObject ? dateObject.getUTCDate() : "";
      const month = dateObject ? dateObject.getUTCMonth() + 1 : ""; // Month is 0-indexed, so add 1
      const year = dateObject ? dateObject.getUTCFullYear() : "";

      // Format the date as "day - month - year"
      const formattedDate = dateObject ? `${day}/${month}/${year}` : "";

      return {
        id: item.unique_id.toString(),
        price: item.price,
        km: item.km,
        year: `${item.year_fabrication}/${item.year_model}`,
        status: item.vehicle_metadata.is_available,
        found: formattedDate,
        link: item.vehicle_metadata.url,
        favorite: item.vehicle_metadata.is_favorite ? true : false,
        notification: item.vehicle_metadata.notification,
      };
    });
  };

  function filterAvailableChartData(data) {
    let available = 0;
    let notAvailable = 0;
    data.vehicles_details.map((item) => {
      if (item.vehicle_metadata.is_available) {
        available = available + 1;
      } else {
        notAvailable = notAvailable + 1;
      }
    });

    let availableBorderColor = theme.palette.success.main;
    let unavailableBorderColor = theme.palette.error.main;
    if (selectedVehicle != null) {
      availableBorderColor = selectedVehicle.vehicle_metadata.is_available
        ? "gold"
        : theme.palette.success.main;
      unavailableBorderColor = selectedVehicle.vehicle_metadata.is_available
        ? theme.palette.error.main
        : "gold";
    }

    const filteredData = {
      labels: ["Disponível", "Vendido"],
      datasets: [
        {
          data: [available, notAvailable],
          borderWidth: 3,
          borderColor: [availableBorderColor, unavailableBorderColor],
          backgroundColor: [
            theme.palette.success.light,
            theme.palette.error.light,
          ],
        },
      ],
    };
    return filteredData;
  }

  function filterYearChartData(data) {
    function getUniqueAscendingList(inputList) {
      // Step 1: Remove duplicates using a Set
      const uniqueSet = new Set(inputList);

      // Step 2: Convert the Set back to an array
      const uniqueList = Array.from(uniqueSet);

      // Step 3: Sort the array in ascending order
      uniqueList.sort((a, b) => a - b);

      return uniqueList;
    }
    function getCountsInOrder(label, years) {
      const countMap = years.reduce((acc, curr) => {
        if (label.includes(curr)) {
          acc[curr] = (acc[curr] || 0) + 1;
        }
        return acc;
      }, {});

      const countsInOrder = label.map((item) => countMap[item] || 0);
      return countsInOrder;
    }
    const vehicles_details = data.vehicles_details;
    const modelYears = vehicles_details.map((item) => {
      return item.year_model;
    });
    const fabricationYears = vehicles_details.map((item) => {
      return item.year_fabrication;
    });
    const allYears = [...modelYears, ...fabricationYears];

    const labels = getUniqueAscendingList(allYears);
    const fabricationYearData = getCountsInOrder(labels, fabricationYears);
    const modelYearData = getCountsInOrder(labels, modelYears);

    let selectedModelYear = null;
    let selectedFabricationYear = null;

    if (selectedVehicle) {
      selectedModelYear = selectedVehicle.year_model || null;
      selectedFabricationYear = selectedVehicle.year_fabrication || null;
    }

    const selectedFabricationYearIndex = labels.indexOf(
      selectedFabricationYear
    ); // Find the index of the selected year in labels array
    const selectedModelYearIndex = labels.indexOf(selectedModelYear); // Find the index of the selected year in labels array

    const fabricationBackgroundColors = labels.map(
      (year, index) =>
        index === selectedFabricationYearIndex ? "gold" : "#63abfd" // Use gold color for selected year, else use existing color
    );
    const modelBackgroundColors = labels.map(
      (year, index) => (index === selectedModelYearIndex ? "gold" : "#e697ff") // Use gold color for selected year, else use existing color
    );

    return {
      labels: labels,
      datasets: [
        {
          label: "Ano de Fabricação",
          data: fabricationYearData,
          backgroundColor: fabricationBackgroundColors, // Apply the modified backgroundColors array here
          borderColor: "#165baa",
          borderWidth: 3,
          borderRadius: 10,
          legendColor: "#63abfd",
        },
        {
          label: "Ano do Modelo",
          data: modelYearData,
          backgroundColor: modelBackgroundColors, // Apply the modified backgroundColors array here
          borderColor: "#a155b9",
          borderWidth: 3,
          borderRadius: 10,
          legendColor: "#e697ff",
        },
      ],
    };
  }

  function filterDetailsData(data) {
    const input = data.filters;
    const formattedData = [];

    // Extracting values from the input object
    const { state, city, make, model, trim } = input;

    // Adding Estado (state) to the formattedData list
    if (state) {
      formattedData.push({ key: "Estado", value: state });
    }

    if (city) {
      formattedData.push({ key: "Cidade", value: city });
    }

    // Adding Marca (make) to the formattedData list
    if (make) {
      formattedData.push({ key: "Marca", value: make.toUpperCase() });
    }

    // Adding Modelo (model) to the formattedData list
    if (model) {
      formattedData.push({ key: "Modelo", value: model });
    }

    // Adding Versão (trim) to the formattedData list
    if (trim) {
      formattedData.push({ key: "Versão", value: trim });
    }

    // Adding other properties to the formattedData list
    // Modify this section as needed for additional properties
    // if (input.vehicle_type) {
    //   formattedData.push({ key: "Tipo de Veículo", value: input.vehicle_type });
    // }
    if (input.price_from !== null) {
      formattedData.push({ key: "Preço Mínimo", value: input.price_from });
    }
    if (input.price_to !== null) {
      formattedData.push({ key: "Preço Máximo", value: input.price_to });
    }
    if (input.year_from !== null) {
      formattedData.push({ key: "Ano Mínimo", value: input.year_from });
    }
    if (input.year_to !== null) {
      formattedData.push({ key: "Ano Máximo", value: input.year_to });
    }
    if (input.km_from !== null) {
      formattedData.push({ key: "Kilometragem Mínima", value: input.km_from });
    }
    if (input.km_to !== null) {
      formattedData.push({ key: "Kilometragem Máxima", value: input.km_to });
    }
    if (input.has_armour !== undefined) {
      formattedData.push({
        key: "Blindado",
        value: input.has_armour ? "Sim" : "Não",
      });
    }

    return formattedData;
  }

  function handleCorrectData(event) {
    setCorrectDataCheck(event.target.checked);
  }

  return (
    <Container sx={{ paddingBottom: "2rem" }}>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <Typography
            paddingY={"2rem"}
            variant="h3"
            fontSize={"min(5vw, 50px)"}
            color={"white"}
          >
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="Ajustar valores incorretos dos anúncios">
            <FormControlLabel
              control={
                <Switch
                  checked={correctDataCheck}
                  onChange={handleCorrectData}
                />
              }
              label="Corrigir Dados"
              sx={{ color: "white" }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={5}>
          <TwoColumnTable title={"Filtros de Busca"} data={detailsData} />
        </Grid>
        <Grid item xs={12} md={5}>
          <TwoColumnTable
            title={"Estatísticas de Mercado"}
            data={marketStatsData}
          />
        </Grid>
        <Grid item xs={12}>
          <FoundVehiclesTable
            data={tableData}
            onSelect={handleRowSelect}
            subscriptionId={id}
            selected={selectedRow}
            notificationHandler={handleNotificationClick}
            favoriteHandler={handleFavoriteClick}
          />
        </Grid>
        <Grid item xs={12}>
          <PriceKmChart data={priceKmChartData} onSelect={handleRowSelect} />
        </Grid>
        <Grid item xs={12} md={6}>
          <YearChart data={yearChartData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <NormalDistributionChart
            title={"Distribuição Normal de Preços dos Veículos"}
            data={priceNormalDistData}
            selectedValue={selectedVehicle ? selectedVehicle.price : null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NormalDistributionChart
            title={"Distribuição Normal da Quilometragem dos Veículos"}
            data={kmNormalDistData}
            selectedValue={selectedVehicle ? selectedVehicle.km : null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AvailableChart
            selectedValue={
              selectedVehicle ? selectedVehicle.is_available : null
            }
            data={availableChartData}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
export default SubscriptionPage;
