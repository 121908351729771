import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import config from "../../config";
import { Switch } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

function SearchForm({ handleApiRequest }) {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedTrim, setSelectedTrim] = useState(null);
  const [kmFrom, setKmFrom] = useState("");
  const [kmTo, setKmTo] = useState("");
  const [yearFrom, setYearFrom] = useState("");
  const [yearTo, setYearTo] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [availableStates, setAvailableStates] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [availableMakes, setAvailableMakes] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [availableTrims, setAvailableTrims] = useState([]);
  const [withArmor, setWithArmor] = useState(false);
  const [withoutArmor, setWithoutArmor] = useState(false);
  const [hasArmour, setHasArmour] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const disabledColor = "#9E9E9E";

  // Reusable sorting function
  const sortByProperty = (arr, sortBy, withinType) => {
    arr.sort((a, b) => {
      // Compare sortBy property
      if (a[sortBy] === b[sortBy]) {
        // If sortBy property is the same, compare withinType property
        return a[withinType].localeCompare(b[withinType]);
      }

      // 'false' comes before 'true'
      return a[sortBy] ? 1 : -1;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(config.apiUrl);
        const result = await axios.get(config.apiUrl + "/states");
        const states = result.data.map((state) => ({
          value: `${state.name} (${state.abbreviation})`,
          id: state.id,
          locked: state.locked,
        }));
        sortByProperty(states, "locked", "value");
        setAvailableStates(states);
      } catch (error) {
        console.error("Error fetching states:", error);
        // Handle error as needed
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(config.apiUrl);
        const result = await axios.get(
          config.apiUrl + `/states/${selectedState.id}/cities`
        );
        const cities = result.data.map((city) => ({
          id: city.id,
          value: city.name,
          locked: city.locked,
        }));
        sortByProperty(cities, "locked", "value");
        setAvailableCities(cities);
      } catch (error) {
        console.error("Error fetching cities:", error);
        // Handle error as needed
      }
    };
    {
      selectedState && fetchData();
    }
  }, [selectedState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(config.apiUrl);
        const result = await axios.get(config.apiUrl + "/makes");
        const makes = result.data.map((make) => ({
          value: make.name,
          id: make.id,
          locked: make.locked,
        }));
        sortByProperty(makes, "locked", "value");
        setAvailableMakes(makes);
      } catch (error) {
        console.error("Error fetching makes:", error);
        // Handle error as needed
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          config.apiUrl + `/makes/${selectedMake.id}/models`
        );
        const models = result.data.map((model) => ({
          id: model.id,
          value: model.name,
          locked: model.locked,
        }));
        sortByProperty(models, "locked", "value");
        setAvailableModels(models);
      } catch (error) {
        console.error("Error fetching models:", error);
        // Handle error as needed
      }
    };
    {
      selectedMake && fetchData();
    }
  }, [selectedMake]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          config.apiUrl +
            `/makes/${selectedMake.id}/models/${selectedModel.id}/versions`
        );
        const versions = result.data.map((version) => ({
          id: version.id,
          value: version.name,
          locked: version.locked,
        }));
        sortByProperty(versions, "locked", "value");
        setAvailableTrims(versions);
      } catch (error) {
        console.error("Error fetching versions:", error);
        // Handle error as needed
      }
    };
    {
      selectedModel && fetchData();
    }
  }, [selectedModel]);

  useEffect(() => {
    if (withArmor && withoutArmor) {
      setWithArmor(false);
      setWithoutArmor(false);
      setHasArmour(null);
    } else if (withArmor && !withoutArmor) {
      setHasArmour(true);
    } else if (withoutArmor && !withArmor) {
      setHasArmour(false);
    } else if (!withArmor && !withoutArmor) {
      setWithArmor(false);
      setWithoutArmor(false);
      setHasArmour(null);
    }
  }, [withArmor, withoutArmor]);

  // Update city options based on the selected state
  const handleStateChange = (event, value) => {
    setSelectedState(value);
    setSelectedCity();
  };

  // Update model options based on the selected make
  const handleMakeChange = (event, value) => {
    setSelectedMake(value);
    setSelectedModel(null);
    setSelectedTrim(null);
  };

  // Update version options based on the selected model
  const handleModelChange = (event, value) => {
    setSelectedModel(value);
    setSelectedTrim(null);
  };

  const handleTrimChange = (event, value) => {
    setSelectedTrim(value);
  };
  // Handler to filter out non-numeric characters
  const handleNumericInput = (event) => {
    let numericValue = event.target.value.replace(/^0+/, "");
    numericValue = numericValue.replace(/[^0-9]/g, "");
    event.target.value = numericValue;
  };
  // Handler to filter out non-numeric characters and limit "Ano" fields to 4 digits
  const handleYearInput = (event) => {
    handleNumericInput(event);
    const currentValue = event.target.value;
    const currentLength = currentValue.length;
    const maxDigits = 4;
    if (currentLength > maxDigits) {
      event.target.value = currentValue.slice(0, maxDigits);
    }
  };

  const handleWithArmorClick = () => {
    setWithArmor(!withArmor);
  };

  const handleWithoutArmorClick = () => {
    setWithoutArmor(!withoutArmor);
  };

  const validateKm = () => {
    if (kmFrom !== "" && kmTo !== "") {
      if (parseInt(kmFrom) > parseInt(kmTo)) {
        // Swap the values if necessary
        const temp = kmFrom;
        setKmFrom(kmTo);
        setKmTo(temp);
      }
    }
  };

  const validatePrice = () => {
    if (priceFrom !== "" && priceTo !== "") {
      if (parseInt(priceFrom) > parseInt(priceTo)) {
        // Swap the values if necessary
        const temp = priceFrom;
        setPriceFrom(priceTo);
        setPriceTo(temp);
      }
    }
  };

  const validateYearTo = () => {
    if (yearTo.length !== 4) {
      setYearTo("");
      return;
    }

    if (parseInt(yearTo) > 2024 || parseInt(yearTo) <= 1800) {
      setYearTo("");
    }
    validateYear();
  };

  const validateYearFrom = () => {
    if (yearFrom.length !== 4) {
      setYearFrom("");
      return;
    }
    if (parseInt(yearFrom) > 2024 || parseInt(yearFrom) <= 1800) {
      setYearFrom("");
    }
    validateYear();
  };

  const validateYear = () => {
    if (yearFrom !== "" && yearTo !== "") {
      if (parseInt(yearFrom) > parseInt(yearTo)) {
        // Swap the values if necessary
        const temp = yearFrom;
        setYearFrom(yearTo);
        setYearTo(temp);
      }
    }
  };

  const handleFormSubmit = async (event, handleApiRequest) => {
    event.preventDefault();

    // setErrorResponse(false);
    // setErrorMessage("");
    // setWaiting(true);

    const formData = {
      state_id: selectedState ? selectedState.id : null,
      city_id: selectedCity ? selectedCity.id : null,
      make_id: selectedMake ? selectedMake.id : null,
      model_id: selectedModel ? selectedModel.id : null,
      version_id: selectedTrim ? selectedTrim.id : null,
      state: selectedState ? selectedState.value : null,
      city: selectedCity ? selectedCity.value : null,
      make: selectedMake ? selectedMake.value : null,
      model: selectedModel ? selectedModel.value : null,
      version: selectedTrim ? selectedTrim.value : null,
      price_from: priceFrom ? priceFrom : null,
      price_to: priceTo ? priceTo : null,
      year_from: yearFrom ? yearFrom : null,
      year_to: yearTo ? yearTo : null,
      km_from: kmFrom ? kmFrom : null,
      km_to: kmTo ? kmTo : null,
      has_armour: hasArmour,
    };

    const validData = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (
          [
            "price_from",
            "price_to",
            "year_from",
            "year_to",
            "km_from",
            "km_to",
          ].includes(key)
        ) {
          validData[key] = parseInt(formData[key]);
        } else {
          validData[key] = formData[key];
        }
      }
    });

    try {
      await handleApiRequest(validData);
    } catch (error) {
      // Handle errors here if needed
      console.error("Error:", error);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ backgroundColor: "white", marginBlock: "2rem" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={availableStates}
            getOptionLabel={(option) => option.value}
            getOptionDisabled={(option) => option.locked}
            value={selectedState ? selectedState : null}
            onChange={handleStateChange}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={option.value} />
                {option.locked && <LockIcon />}
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Estado" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={availableCities}
            value={selectedCity ? selectedCity : null}
            getOptionLabel={(option) => option.value}
            getOptionDisabled={(option) => option.locked}
            onChange={(event, value) => setSelectedCity(value)}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={option.value} />
                {option.locked && <LockIcon />}
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cidade" variant="outlined" />
            )}
            disabled={!selectedState}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={availableMakes}
            value={selectedMake ? selectedMake : null}
            getOptionLabel={(option) => option.value}
            getOptionDisabled={(option) => option.locked}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={option.value} />
                {option.locked && <LockIcon />}
              </ListItem>
            )}
            onChange={handleMakeChange}
            renderInput={(params) => (
              <TextField {...params} label="Marca" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={availableModels}
            value={selectedModel ? selectedModel : null}
            getOptionLabel={(option) => option.value}
            getOptionDisabled={(option) => option.locked}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={option.value} />
                {option.locked && <LockIcon />}
              </ListItem>
            )}
            onChange={handleModelChange}
            renderInput={(params) => (
              <TextField {...params} label="Modelo" variant="outlined" />
            )}
            disabled={!selectedMake}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={availableTrims}
            value={selectedTrim ? selectedTrim : null}
            getOptionLabel={(option) => option.value}
            getOptionDisabled={(option) => option.locked}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={option.value} />
                {option.locked && <LockIcon />}
              </ListItem>
            )}
            onChange={handleTrimChange}
            renderInput={(params) => (
              <TextField {...params} label="Versão" variant="outlined" />
            )}
            disabled={!selectedModel}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography paddingBottom={"3px"}>Preço</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="de"
                variant="outlined"
                value={priceFrom}
                onChange={(e) => setPriceFrom(e.target.value)}
                onBlur={validatePrice}
                onInput={handleNumericInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="até"
                variant="outlined"
                value={priceTo}
                onChange={(e) => setPriceTo(e.target.value)}
                onBlur={validatePrice}
                onInput={handleNumericInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h8">Km</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="de"
                variant="outlined"
                value={kmFrom}
                onChange={(e) => setKmFrom(e.target.value)}
                onBlur={validateKm}
                onInput={handleNumericInput}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="até"
                variant="outlined"
                value={kmTo}
                onChange={(e) => setKmTo(e.target.value)}
                onBlur={validateKm}
                onInput={handleNumericInput}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>Ano</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="de"
                variant="outlined"
                value={yearFrom}
                onChange={(e) => setYearFrom(e.target.value)}
                onBlur={validateYearFrom}
                onInput={handleYearInput}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="até"
                variant="outlined"
                value={yearTo}
                onChange={(e) => setYearTo(e.target.value)}
                onBlur={validateYearTo}
                onInput={handleYearInput}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography color={disabledColor}>Rotatividade máxima</Typography>
          <TextField
            fullWidth
            disabled
            size="small"
            label="dias"
            variant="outlined"
            onChange={(e) => setYearTo(e.target.value)}
            onBlur={validateYearTo}
            onInput={handleYearInput}
          />
        </Grid>
        <Grid item xs={9} alignSelf={"center"}>
          <Typography color={disabledColor}>Dados históricos</Typography>
        </Grid>
        <Grid item xs={3} alignSelf={"center"}>
          <Switch disabled />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} mt={"1rem"}>
          <FormControlLabel
            checked={withArmor}
            onClick={handleWithArmorClick}
            control={<Checkbox />}
            label="Com Blindagem"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            checked={withoutArmor}
            onClick={handleWithoutArmorClick}
            control={<Checkbox />}
            label="Sem Blindagem"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={"2rem"}>
        <Divider />
        <Button
          size="small"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={(event) => handleFormSubmit(event, handleApiRequest)}
        >
          {waiting ? <CircularProgress size={24} thickness={5} /> : "Pesquisar"}
        </Button>
        <Typography color={"error"}>
          {errorResponse ? errorMessage : ""}
        </Typography>
      </Grid>
    </Container>
  );
}

export default SearchForm;
