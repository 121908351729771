import React from "react";
import "./helppage.css";
import { Typography } from "@mui/material";

function HelpPage() {
  return (
    <div className="instructions">
      {/* <Typography color="white">
        <ol>
          <li>Faça uma pesquisa no Webmotors.</li>
          <li>
            Fique atento ao número de veículos, o número máximo pode variar de
            acordo com seu plano.
          </li>
          <li>Copie o URL e cole no campo de adicionar veículos.</li>
          <li>
            Aguarde a validação de sua pesquisa. Isto pode levar alguns minutos.
          </li>
          <li>
            Pronto! A entrada de novos veículos está sendo monitorada para você.
            Novas notificações serão enviadas por e-mail.
          </li>
        </ol>
      </Typography> */}
      {/* <div className="spacer"></div> */}
      <div className="contact">
        <Typography color="white">Contato: </Typography>
        <a href="mailto:contato@autohunt.com.br">
          <Typography color="primary">contato@autohunt.com.br</Typography>
        </a>
      </div>
    </div>
  );
}
export default HelpPage;
