import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./landingpage.module.css";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import bigLogo from "../../assets/autohunt_logos/autohunt_logo_big_green.png";
import LandingPageAppBar from "./LandingPageAppBar";

function LandingPage() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  let navigate = useNavigate();

  const handleSignUpPress = () => {
    navigate(`/signup?name=${name}&surname=${surname}&email=${email}`);
  };
  return (
    <>
      <LandingPageAppBar />
      <Grid container height={"calc(100% - 64px)"} alignItems={"center"}>
        <Grid
          item
          xs={12}
          height={"100%"}
          md={6}
          display={"flex"}
          justifyContent="center"
        >
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            height={"100%"}
            maxWidth={"min(60%, 450px)"}
            direction={"column"}
            paddingY={4}
            justifyContent={{ xs: "space-between", md: "center" }}
          >
            <Grid item>
              <img
                src={bigLogo}
                alt="autohunt"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography color={"white"} align={"justify"}>
                {" "}
                Seu Caminho para o Carro Ideal,{" "}
                <span>Desenhado para Você.</span>
                <p>
                  Encontrar o carro perfeito pode ser um desafio em meio a
                  tantas opções disponíveis.
                </p>
                <p>
                  No <span>Auto</span>Hunt, transformamos essa jornada em algo
                  simples e gratificante.{" "}
                </p>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                style={{
                  width: "100%",
                  borderRadius: "60px",
                }}
                variant="contained"
                onClick={handleSignUpPress}
              >
                Comece a Sua Jornada Automotiva Agora!
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          justifyContent="center"
          display={{ xs: "none", md: "flex" }}
          md={6}
        >
          <Paper
            className={style["signup-card-container"]}
            sx={{
              display: "flex",
              width: "min(250px, 100%)",
              padding: "1rem  1rem",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "20px",
            }}
          >
            <div className={style["signup-card-text-container"]}>
              <Typography>Cadastro</Typography>
              <div className={style["signup-card-text-title-image"]}></div>
              <div className={style["signup-card-text-subtitle"]}></div>
            </div>
            <div className={style["signup-card-name-surname"]}>
              <TextField
                variant="outlined"
                size="medium"
                label="Nome"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                variant="outlined"
                size="medium"
                label="Sobrenome"
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div className={style["signup-card-email"]}>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                size="medium"
                label="E-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Button
              sx={{
                color: "white",
                borderRadius: "20px",
                width: "100%",
                fontWeight: "bold",
                backgroundColor: "#9e9e9e",
              }}
              color="secondary"
              size="large"
              state="enabled"
              variant="contained"
              onClick={handleSignUpPress}
            >
              Criar Conta
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default LandingPage;
