import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../themes/theme";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PriceKmChart from "../../components/charts/PriceKmChart";
import TwoColumnList from "../../components/table/TwoColumnList";
import { useMediaQuery } from "@mui/material";
import Logo from "../../assets/autohunt_logos/autohunt_logo_big_black.png";
import Logo2 from "../../assets/autohunt_logos/autohunt_logo_big_green_black.png";
import DashboardFormSubmit from "./DashboardFormSubmit";
import SearchForm from "../NewSearchPage/SearchForm";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LockIcon from "@mui/icons-material/Lock";

const screenWidth = window.innerWidth; // Screen width in pixels
const drawerWidth = Math.min(screenWidth - 60, 350); // Replace 600 with your desired integer value

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("xs")]: {
        width: open ? theme.spacing(9) : 0,
      },
    }),
  },
}));

export default function Dashboard() {
  const [turnover, setTurnover] = useState(
    <LockIcon sx={{ color: "#9E9E9E" }} />
  );
  const [recommendedPrice, setRecommendedPrice] = useState(
    <LockIcon sx={{ color: "#9E9E9E" }} />
  );
  const [vehiclesFound, setVehiclesFound] = useState(0);
  const [vehiclesAvailable, setVehiclesAvailable] = useState(86);
  const [averagePrice, setAveragePrice] = useState("R$ 127.000,00");
  const [averageKm, setAverageKm] = useState("95.000");
  const [open, setOpen] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [priceKmChartData, setPriceKmChartData] = useState({
    datasets: [
      {
        label: "",
        backgroundColor: [""],
        data: [],
      },
    ],
  });
  const [dashboardData, setDashboardData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  useEffect(() => {
    setSelectedId(null);
    setSelectedVehicle(null);
  }, [dashboardData]);

  useEffect(() => {
    const vehicle = getSelectedVehicle();
    setSelectedVehicle(vehicle);
  }, [selectedId]);

  function getSelectedVehicle() {
    for (let i = 0; i < dashboardData.length; i++) {
      if (dashboardData[i].id == selectedId) {
        return {
          id: dashboardData[i].id,
          price: dashboardData[i].price.toLocaleString("pt-Br", {
            style: "currency",
            currency: "BRL",
          }),
          km: `${dashboardData[i].odometer.toLocaleString("pt-BR", {
            style: "decimal",
          })} km`,
          year: `${dashboardData[i].year_fabrication}/${dashboardData[i].year_model}`,
          color: dashboardData[i].color,
          make: dashboardData[i].make,
          model: dashboardData[i].model,
          version: dashboardData[i].version,
          status: dashboardData[i].is_available ? "Disponível" : "Vendido",
          createdDate: <LockIcon fontSize="small" />,
          link: dashboardData[i].link,
          sellerType: dashboardData[i].seller_type,
          isFavorite: <LockIcon fontSize="small" />,
          armored: dashboardData[i].armored ? "Sim" : "Não",
          comment: dashboardData[i].comment,
        };
      }
    }
  }

  useEffect(() => {}, [selectedId]);

  useEffect(() => {
    setVehiclesFound(dashboardData.length);
  }, [dashboardData]);

  useEffect(() => {
    setVehiclesAvailable(calculateAvailableVehicles(dashboardData));
  }, [dashboardData]);

  function calculateAvailableVehicles(dashboardData) {
    const availableVehicles = dashboardData.filter((element) => {
      return element.is_available;
    });
    return availableVehicles.length;
  }

  useEffect(() => {
    const processedData = getAveragePrice(dashboardData);
    const currencyFormatedAveragePrice = processedData.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: 0,
    });

    setAveragePrice(currencyFormatedAveragePrice);
  }, [dashboardData]);

  function getAveragePrice(dashboardData) {
    const prices = dashboardData.map((element) => {
      return element.price;
    });
    if (prices.length === 0) {
      return 0; // Return NaN for an empty prices or handle it as needed
    }

    const sum = prices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const mean = sum / prices.length;
    return mean;
  }

  useEffect(() => {
    const processedData = getAverageKm(dashboardData);
    const formatedAverageKm = `${processedData.toLocaleString("pt-BR", {
      style: "decimal",
      maximumFractionDigits: 0,
    })} km`;

    setAverageKm(formatedAverageKm);
  }, [dashboardData]);

  function getAverageKm(dashboardData) {
    const km = dashboardData.map((element) => {
      return element.odometer;
    });
    if (km.length === 0) {
      return 0; // Return NaN for an empty km or handle it as needed
    }

    const sum = km.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const mean = sum / km.length;
    return mean;
  }

  useEffect(() => {
    const processedData = processPriceKmChartData(dashboardData);
    setPriceKmChartData(processedData);
  }, [dashboardData, selectedId]);

  function processPriceKmChartData(dashboardData) {
    const availableDataset = {
      label: "Disponível",
      backgroundColor: [],
      data: [],
    };

    const unavailableDataset = {
      label: "Vendido",
      backgroundColor: [],
      data: [],
    };

    dashboardData.map((element) => {
      if (element.is_available) {
        availableDataset.data.push({
          x: element.odometer,
          y: element.price,
          id: element.id,
        });
        if (element.id == selectedId) {
          availableDataset.backgroundColor.push("gold");
        } else {
          availableDataset.backgroundColor.push("#53B364");
        }
      } else if (!element.is_available) {
        unavailableDataset.data.push({
          x: element.odometer,
          y: element.price,
          id: element.id,
        });
        if (element.id == selectedId) {
          unavailableDataset.backgroundColor.push("gold");
        } else {
          unavailableDataset.backgroundColor.push("#393939");
        }
      }
    });

    return {
      datasets: [availableDataset, unavailableDataset],
    };
  }

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleApiRequest = async (validData) => {
    const result = await DashboardFormSubmit(validData);
    setDashboardData(result);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isXsScreen) {
      toggleDrawer();
    }
  }, [dashboardData]);

  function handleSelect(id) {
    if (selectedId == id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>
            <Box
              display={"flex"}
              justifyContent={{ xs: "center", sm: "flex-start" }}
              sx={{
                width: "100%",
              }}
            >
              {open ? (
                <Box />
              ) : (
                <Box
                  component="img"
                  src={Logo}
                  sx={{
                    maxHeight: "1.5rem",
                  }}
                />
              )}
            </Box>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <Badge badgeContent={0} color="secondary">
                <AccountCircleIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            >
              {/* Add menu items as needed */}
              {/* <MenuItem onClick={handleMenuClose}>Meu Perfil</MenuItem>
              <MenuItem onClick={handleMenuClose}>Minhas Buscas</MenuItem>
              <MenuItem onClick={handleMenuClose}>Meus Veículos</MenuItem> */}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          variant="permanent"
          open={open}
          sx={{
            maxHeight: "100vh",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "white",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                justifyContent: "flex-start",
                px: [1],
              }}
            >
              <Box
                sx={{
                  flex: "0 1 auto",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Box
                  component="img"
                  src={Logo2}
                  sx={{
                    maxHeight: "1.5rem",
                  }}
                />
              </Box>
              <IconButton
                onClick={toggleDrawer}
                sx={{
                  flex: "0 1 auto",
                  marginLeft: "auto",
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
          </Box>
          <SearchForm handleApiRequest={handleApiRequest} />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <Typography variant="h5">{turnover}</Typography>
                  <Typography variant="body2" color={"rgba(0,0,0,0.60)"}>
                    Rotatividade
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper
                  sx={{
                    overflow: "hidden",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">{recommendedPrice}</Typography>
                  <Typography variant="body2" color={"rgba(0,0,0,0.60)"}>
                    Preço recomendado
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper
                  sx={{
                    overflow: "hidden",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">{vehiclesFound}</Typography>
                  <Typography variant="body2" color={"rgba(0,0,0,0.60)"}>
                    Veículos encontrados
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper
                  sx={{
                    overflow: "hidden",

                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">{vehiclesAvailable}</Typography>
                  <Typography variant="body2" color={"rgba(0,0,0,0.60)"}>
                    Veículos disponíveis
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper
                  sx={{
                    overflow: "hidden",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">{averagePrice}</Typography>
                  <Typography variant="body2" color={"rgba(0,0,0,0.60)"}>
                    Preço médio
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Paper
                  sx={{
                    overflow: "hidden",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">{averageKm}</Typography>
                  <Typography variant="body2" color={"rgba(0,0,0,0.60)"}>
                    Quilometragem média
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={selectedId ? 8 : 12}>
                <Paper
                  sx={{
                    overflow: "hidden",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "70vh",
                  }}
                >
                  <Typography variant="h6">Preço X Quilometragem</Typography>
                  <PriceKmChart
                    usePaper={false}
                    data={priceKmChartData}
                    onSelect={handleSelect}
                    displayYAxis={!isXsScreen}
                  />
                </Paper>
              </Grid>
              {selectedId && (
                <Grid item xs={12} lg={4}>
                  <Paper
                    sx={{
                      overflow: "hidden",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">Anúncio selecionado</Typography>
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Marca"}
                      value={selectedVehicle ? selectedVehicle.make : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Modelo"}
                      value={selectedVehicle ? selectedVehicle.model : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Versão"}
                      value={selectedVehicle ? selectedVehicle.version : null}
                      noWrap={true}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Preço"}
                      value={selectedVehicle ? selectedVehicle.price : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Quilometragem"}
                      value={selectedVehicle ? selectedVehicle.km : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Ano"}
                      value={selectedVehicle ? selectedVehicle.year : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Cor"}
                      value={selectedVehicle ? selectedVehicle.color : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Blindado"}
                      value={selectedVehicle ? selectedVehicle.armored : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Status"}
                      value={selectedVehicle ? selectedVehicle.status : null}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Data do anúncio"}
                      value={
                        selectedVehicle ? selectedVehicle.createdDate : null
                      }
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Link"}
                      value={selectedVehicle ? selectedVehicle.link : null}
                      linkTo={true}
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Vendedor"}
                      value={
                        selectedVehicle ? selectedVehicle.sellerType : null
                      }
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <TwoColumnList
                      title={"Favorito"}
                      value={
                        selectedVehicle ? selectedVehicle.isFavorite : null
                      }
                    />
                    <Divider sx={{ marginBottom: "10px" }} />
                    <Grid item xs={12}>
                      <Typography variant="body2">Comentário</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box maxHeight={"200px"} overflow={"auto"}>
                        <Typography sx={{ fontSize: "0.7rem" }}>
                          {selectedVehicle ? selectedVehicle.comment : null}
                        </Typography>
                      </Box>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
