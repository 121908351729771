import React from "react";

// Import a placeholder image (or provide a URL to a default image)
import PlaceholderImage from "../../assets/images/logos/placeholder.png";

const CarLogo = ({ carName }) => {
  // Function to get the image URL based on the car name
  const getCarLogo = (carName) => {
    // Replace spaces with dashes in the car name
    const formattedCarName = carName.replace(/ /g, "-").toLowerCase();
    // Assuming the images are named with lowercase formatted car names
    const imageName = formattedCarName + ".png";

    try {
      // Check if the image exists, and import it dynamically
      // If the image doesn't exist, this will throw an error
      const imageUrl = require(`../../assets/images/logos/${imageName}`);
      return imageUrl;
    } catch (error) {
      // If the image doesn't exist or there's an error, return the placeholder image
      return PlaceholderImage;
    }
  };

  return (
    <div style={{ width: "4rem", height: "4rem", marginRight: "1rem" }}>
      {/* Use the <img> tag with the URL returned by the getCarLogo function */}
      <img
        src={getCarLogo(carName)}
        alt={`${carName} Logo`}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    </div>
  );
};

export default CarLogo;
