import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Paper } from "@mui/material";
import theme from "../../themes/theme";

function YearChart({ data }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  ChartJS.defaults.font.family = theme.typography.fontFamily;

  const config = {
    options: {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          type: "linear", // Correct scale type for a linear y-axis
          beginAtZero: true,
          ticks: {
            stepSize: 1, // To ensure only integers are displayed
            callback: (value) => {
              return Number.isInteger(value) ? value : ""; // Display integers only
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    },
  };

  return (
    <Container
      component={Paper}
      sx={{ height: "412px", borderRadius: "6px", padding: "2rem" }}
    >
      <Bar data={data} options={config.options} />
    </Container>
  );
}

export default YearChart;
