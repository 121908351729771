import React, { useEffect, useState } from "react";
import style from "./mysubscriptionspage.module.css";
import SubscriptionTable from "../../components/new_subscriptions_page/SubscriptionsTable";
import axios from "axios";
import config from "../../config";

function SubscriptionsPage() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const access_token = localStorage.getItem("access_token");
      const result = await axios.get(config.apiUrl + "/subscriptions", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const filteredData = filterData(result.data);
      setTableData(filteredData);
    };
    fetchData();
  }, []);

  const filterData = (data) => {
    return data.map((item) => ({
      id: item._id.$oid,
      make: item.filters.make,
      model: item.filters.model,
      trim: item.filters.trim,
      status: item.is_valid,
      hasNotification: item.new_vehicles,
    }));
  };

  const handleUpdateTableData = (id) => {
    const newTableData = tableData.filter((s) => s.id !== id);
    setTableData(newTableData);
  };

  return (
    <div className={style["page-container"]}>
      <div className={style["content-container"]}>
        <SubscriptionTable
          data={tableData}
          updateTableData={handleUpdateTableData}
        />
      </div>
    </div>
  );
}

export default SubscriptionsPage;
