import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import isLoggedIn from "../../services/auth";
import usePaymentStatus from "../auth/usePaymentStatus";
import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import logoImage from "../../assets/autohunt_logos/autohunt_logo_small.png";

function ResponsiveAppBar({ pages }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem("access_token");
  };

  if (!isLoggedIn()) {
    return <></>;
  }

  return (
    <AppBar
      sx={{ paddingBlock: "8px", backgroundColor: "var(--background-color)" }}
      position="sticky"
      elevation={0}
    >
      <Container
        maxWidth="false"
        sx={{
          display: {
            xs: "flex",
            md: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
        }}
      >
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <img src={logoImage} style={{ height: "30px" }} />
            </Link>
          </Box>
          <Box sx={{ display: "flex" }}>
            {pages.map((page) => (
              <Link
                key={page.name}
                to={page.route}
                style={{ textDecoration: "none", marginInline: "20px" }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    paddingTop: 1,
                  }}
                >
                  <Typography sx={{ ":hover": { color: "white" } }}>
                    {page.name}
                  </Typography>
                </Button>
              </Link>
            ))}
          </Box>
        </Box>
        <Box
          onClick={handleLogOut}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Link
            to="/signin"
            onClick={() => window.location.reload()}
            style={{
              textDecoration: "none",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <Tooltip title="Logout">
              <IconButton>
                <LogoutIcon
                  color="primary"
                  sx={{ ":hover": { color: "white" } }}
                ></LogoutIcon>
              </IconButton>
            </Tooltip>
          </Link>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: {
              xs: "flex",
              md: "none",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                <Link
                  to={page.route}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </Link>
              </MenuItem>
            ))}
          </Menu>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <IconButton>
              <img src={logoImage} style={{ height: "30px" }} />
            </IconButton>
          </Link>
          <Box
            onClick={handleLogOut}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <Link
              to="/signin"
              onClick={() => window.location.reload()}
              style={{
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              <IconButton sx={{ color: "white" }}>
                <LogoutIcon />
              </IconButton>
            </Link>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
}

function ResponsiveAppBarContainer() {
  const { isPaying, isLoading } = usePaymentStatus();

  if (isLoading) {
    return <></>;
  }
  let pages = [
    // { name: "Meu Perfil", route: "/profile" },
    // { name: "Estatísticas", route: "/statistics" },
    // { name: "Oportunidades", route: "/opportunities" },
    // { name: "Ajuda", route: "/help" },
  ];

  // const freePages = [{ name: "Upgrade de Plano", route: "/pricing" }];
  const freePages = [];

  if (!isPaying) {
    pages = [...pages, ...freePages];
  }

  return <ResponsiveAppBar pages={pages} />;
}

export default ResponsiveAppBarContainer;
