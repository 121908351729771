import axios from "axios";
import config from "../../config";

const handleApiRequest = async (validData) => {
  try {
    const result = await axios.get(config.apiUrl + "/dashboard", {
      params: validData,
    });
    return result.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle error as needed
  }
};

export default handleApiRequest;
