import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";

const usePaymentStatus = () => {
  const [isPaying, setIsPaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const fetchPaymentStatus = async () => {
      const response = await axios.get(config.apiUrl + "/get-user-details", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return response.data;
    };

    fetchPaymentStatus()
      .then((user) => {
        setIsPaying(user.is_paying);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  return { isPaying, isLoading };
};

export default usePaymentStatus;
