import React, { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import theme from "../../themes/theme";
import {
  Toolbar,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  IconButton,
  Typography,
  Button,
  Box,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CarLogo from "../images/CarLogo";

function SubscriptionsTable({ data, updateTableData }) {
  const [open, setOpen] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  const navigate = useNavigate();

  const handleNewSearch = () => {
    navigate("/createsubscription");
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    setOpen(false);
    // Proceed with the deletion
    const access_token = localStorage.getItem("access_token");
    await axios.delete(
      config.apiUrl + `/subscriptions/${selectedSubscriptionId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    // Update table data after deletion
    updateTableData(selectedSubscriptionId);
  };
  const handleDelete = (event, id) => {
    event.stopPropagation(); // Stop the click event from propagating to the table row
    handleOpenDialog(id);
  };
  const handleOpenDialog = (id) => {
    setSelectedSubscriptionId(id);
    setOpen(true);
  };

  const handleSubscripitonClick = (id) => {
    updateNotification(id, false);
    navigate(`/subscriptions/${id}`);
  };

  const updateNotification = async (subscriptionId, notification) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // Send the `is_favorite` parameter in the request query parameters
    const params = new URLSearchParams({ notification: notification });

    try {
      const result = await axios.post(
        config.apiUrl +
          `/subscriptions/${subscriptionId}/notification?${params}`,
        {},
        {
          headers: headers,
        }
      );

      return result;
    } catch (error) {
      throw new Error("Failed to update favorite status");
    }
  };

  const headers = ["", "Marca", "Modelo", "Versão", "Status"];

  const statusColors = {
    true: "#ebebeb", // Green for "Ativa"
    false: "#ed6c02", // Orange for "Suspendida"
    // Add more status-color pairs as needed
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "6px",
        maxHeight: "90%",
        maxWidth: "80%",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: "30px",
          }}
        >
          Minhas Buscas
        </Typography>
        <Button
          color="secondary"
          size="small"
          sx={{ gap: "10px" }}
          variant="contained"
          onClick={handleNewSearch}
        >
          <AddIcon></AddIcon>
          Nova Busca
        </Button>
      </Toolbar>
      <Table stickyHeader size="small">
        <TableHead sx={{ height: "50px" }}>
          <TableRow key="header-key">
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              sx={{ ":hover": { cursor: "pointer" } }}
              hover={true}
              key={row.id}
              onClick={() => handleSubscripitonClick(row.id)}
            >
              <TableCell>
                {row.hasNotification ? (
                  <CircleIcon color="primary" sx={{ fontSize: "10px" }} />
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell
                sx={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Truncate with ellipsis
                }}
              >
                <Box sx={{ height: "100%" }}>
                  <CarLogo carName={row.make} />
                </Box>
                {row.make}
              </TableCell>

              <TableCell
                sx={{
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Truncate with ellipsis
                }}
              >
                {row.model}
              </TableCell>
              <TableCell
                sx={{
                  maxWidth: "20vw",
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Truncate with ellipsis
                }}
              >
                {row.trim}
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "0.5rem",
                    borderRadius: "20px",
                    backgroundColor: statusColors[row.status] || "gray",
                  }}
                >
                  <Typography>{row.status ? "Ativa" : "Suspendida"}</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  onClick={(event) => handleDelete(event, row.id)}
                  sx={{ ":hover": { color: theme.palette.error.main } }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Deletar Busca</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja remover esta busca?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

export default SubscriptionsTable;
