import React from "react";
import { Grid, Container, TextField, Autocomplete, Paper } from "@mui/material";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

function PriceKmChart({
  data,
  onSelect,
  usePaper = true,
  displayYAxis = true,
}) {
  const kmFormatter = new Intl.NumberFormat("pt-BR");

  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  const handlePointClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedElement = elements[0];
      const datasetIndex = clickedElement.datasetIndex;
      const index = clickedElement.index;

      const clickedId = data.datasets[datasetIndex].data[index].id;
      onSelect(clickedId); // Call the onSelect function with the clicked ID
    }
  };
  const config = {
    options: {
      onClick: handlePointClick,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: (value) => kmFormatter.format(value), // Apply kilometer formatting
          },
        },
        y: {
          display: displayYAxis ? true : false,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value) => currencyFormatter.format(value), // Apply currency formatting
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
      elements: {
        point: {
          hoverRadius: 10, // Set the hover radius to your desired size
        },
      },
    },
  };

  function FilterToolbar() {
    const trims = ["Turbo", "Aspirado", "Supercharger"];
    const fabricationYears = [2019, 2020, 2021];
    const modelYears = [2020, 2021, 2022];
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Autocomplete
            disablePortal
            id="trim"
            options={trims}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Versão" />}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            disablePortal
            id="fab-year"
            options={fabricationYears}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Ano de Fabricação" />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            disablePortal
            id="model-year"
            options={modelYears}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Ano do modelo" />
            )}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Container
      component={usePaper ? Paper : undefined}
      sx={{ height: "100%", borderRadius: "6px", padding: "2rem" }}
    >
      <Scatter data={data} options={config.options} />
    </Container>
  );
}

export default PriceKmChart;
