import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import queryString from "query-string";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Auto Hunt
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validate = () => {
    let isError = false;

    if (password.length === 0) {
      setPasswordError("Password is required");
      isError = true;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      isError = true;
    } else {
      setPasswordError("");
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Please confirm your password");
      isError = true;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      isError = true;
    } else {
      setConfirmPasswordError("");
    }

    return isError;
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { token } = queryString.parse(window.location.search);

    if (!validate()) {
      const response = await axios
        .post(config.apiUrl + "/reset-password", {
          token: token,
          password: password,
        })
        .then(() => navigate("/"));
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        minWidth: "300px",
      }}
    >
      <Paper
        sx={{
          maxWidth: "80%",
          backgroundColor: "white",
          borderRadius: "20px",
          paddingInline: "2rem",
        }}
      >
        /
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBlock: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              alignSelf: "flex-start",
            }}
          >
            Redefinir Senha
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Nova senha"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setPassword(event.target.value)}
                  error={passwordError.length > 0}
                  helperText={passwordError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirmar senha"
                  type="password"
                  id="confirmPassword"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  error={confirmPasswordError.length > 0}
                  helperText={confirmPasswordError}
                />
              </Grid>
            </Grid>
            <Button
              color="secondary"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Resetar senha
            </Button>
            <Grid container justifyContent="flex-end"></Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3, paddingBlock: "1rem" }} />
      </Paper>
    </Box>
  );
}
