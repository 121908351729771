import React, { useEffect, useState, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import config from "../../config";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Box,
  Toolbar,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import theme from "../../themes/theme";
import axios from "axios";
import { visuallyHidden } from "@mui/utils";

function FoundVehiclesTable({
  data,
  subscriptionId,
  onSelect,
  selected,
  notificationHandler,
  favoriteHandler,
}) {
  const [tableData, setTableData] = useState(data);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("found");
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  useEffect(() => {
    // Find the index of the selected row in the tableData array
    const rowIndex = tableData.findIndex((row) => row.id == selected);

    // Update the selectedRowIndex state with the index
    setSelectedRowIndex(rowIndex);
  }, [selected, tableData]);

  useEffect(() => {
    onSelect(selected); // Call the onSelect function from props to update the selected row
  }, [selected]);

  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll the container to center the selected row
    if (selectedRowIndex !== -1 && containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const rowHeight = 73;
      const scrollToY =
        selectedRowIndex * rowHeight - containerHeight / 2 + 2 * rowHeight; // Assuming row height is 73
      containerRef.current.scrollTop = scrollToY;
    }
  }, [selectedRowIndex]);

  const headCells = [
    {
      id: "notification",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "Preço",
    },
    {
      id: "km",
      numeric: true,
      disablePadding: false,
      label: "Quilometragem",
    },
    {
      id: "year",
      numeric: true,
      disablePadding: false,
      label: "Ano",
    },
    {
      id: "found",
      numeric: false,
      disablePadding: false,
      label: "Encontrado",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "link",
      numeric: false,
      disablePadding: false,
      label: "Link",
    },
    {
      id: "favorite",
      numeric: false,
      disablePadding: false,
      label: "Favorito",
    },
  ];

  useEffect(() => {
    const sortedData = stableSort(data, getComparator(order, orderBy));
    setTableData(sortedData);
  }, [data]);

  useEffect(() => {
    onSelect(selected); // Call the onSelect function from props to update the selected row
  }, [selected]);

  const handleSelect = (rowId) => {
    onSelect(rowId);
  };

  const handleToggleFavorite = async (event, id) => {
    event.stopPropagation();

    const rowIndex = tableData.findIndex((row) => row.id === id);
    const favorite = !tableData[rowIndex].favorite;

    favoriteHandler(id, favorite);

    try {
      await updateFavorite(subscriptionId, id, favorite);
    } catch (error) {
      // Handle any errors here
      console.error("Error updating favorite:", error);
    }
  };

  const handleLinkClick = async (id) => {
    // Find the index of the row with the given id in the tableData array
    const rowIndex = tableData.findIndex((row) => row.id === id);

    // If the row is found, toggle its favorite property
    if (rowIndex !== -1) {
      const updatedRow = {
        ...tableData[rowIndex],
        notification: false,
      };

      // Create a new array with the updated row
      const updatedTableData = [...tableData];
      updatedTableData[rowIndex] = updatedRow;

      // Set the tableData state with the updated array
      setTableData(updatedTableData);

      // Call updateFavorite with the correct parameters
      try {
        await updateNotification(subscriptionId, id, false);
      } catch (error) {
        // Handle any errors here
        console.error("Error updating notification:", error);
      }
    }
  };

  const handleNotificationClick = async (event, id) => {
    event.stopPropagation();

    const rowIndex = tableData.findIndex((row) => row.id === id);
    const notification = !tableData[rowIndex].notification;

    notificationHandler(id, notification);

    try {
      await updateNotification(subscriptionId, id, notification);
    } catch (error) {
      // Handle any errors here
      console.error("Error updating favorite:", error);
    }
  };

  const updateNotification = async (
    subscriptionId,
    vehicleId,
    notification
  ) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // Send the `is_favorite` parameter in the request query parameters
    const params = new URLSearchParams({ notification: notification });

    try {
      const result = await axios.post(
        config.apiUrl +
          `/subscriptions/${subscriptionId}/${vehicleId}/notification?${params}`,
        {},
        {
          headers: headers,
        }
      );

      return result;
    } catch (error) {
      throw new Error("Failed to update favorite status");
    }
  };

  const updateFavorite = async (subscriptionId, vehicleId, isFavorite) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // Send the `is_favorite` parameter in the request query parameters
    const params = new URLSearchParams({ is_favorite: isFavorite });

    try {
      const result = await axios.post(
        config.apiUrl +
          `/subscriptions/${subscriptionId}/${vehicleId}/favorite?${params}`,
        {},
        {
          headers: headers,
        }
      );

      return result;
    } catch (error) {
      throw new Error("Failed to update favorite status");
    }
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        {" "}
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => ascendingComparator(a, b, orderBy);
  }

  function parseDateFromString(dateString) {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  }

  function ascendingComparator(a, b, orderBy) {
    if (orderBy === "found") {
      // Handle the case when either a[orderBy] or b[orderBy] is an empty string
      if (!a[orderBy] && !b[orderBy]) return 0; // Both are empty, consider them equal
      if (!a[orderBy]) return -1; // a[orderBy] is empty, consider it smaller
      if (!b[orderBy]) return 1; // b[orderBy] is empty, consider it smaller

      const dateA = parseDateFromString(a[orderBy]);
      const dateB = parseDateFromString(b[orderBy]);
      return dateA - dateB;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  function descendingComparator(a, b, orderBy) {
    return -ascendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = stableSort(
      tableData,
      getComparator(isAsc ? "desc" : "asc", property)
    );
    setTableData(sortedData);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const kmFormatter = new Intl.NumberFormat("pt-BR");

  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "6px",
        maxHeight: "412px",
      }}
      ref={containerRef}
    >
      <Toolbar>
        <Typography
          sx={{
            fontSize: "30px",
          }}
        >
          Anúncios encontrados
        </Typography>
      </Toolbar>
      <Table stickyHeader>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={tableData.length}
        />
        <TableBody>
          {tableData.map((row) => (
            <TableRow
              selected={selected == row.id}
              hover
              key={row.id}
              onClick={() => handleSelect(row.id)}
            >
              <TableCell>
                <IconButton
                  onClick={(event) => handleNotificationClick(event, row.id)}
                >
                  {row.notification ? (
                    <CircleIcon color="primary" sx={{ fontSize: "10px" }} />
                  ) : (
                    ""
                  )}
                </IconButton>
              </TableCell>
              <TableCell>{currencyFormatter.format(row.price)}</TableCell>
              <TableCell>{kmFormatter.format(row.km)}</TableCell>
              <TableCell>{row.year}</TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Truncate with ellipsis
                }}
              >
                {row.found}
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "0.5rem",
                    borderRadius: "20px",
                    backgroundColor: row.status
                      ? theme.palette.success.light
                      : theme.palette.error.light,
                  }}
                >
                  <Typography>
                    {row.status ? "Disponível" : "Vendido"}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  maxWidth: "20vw",
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Truncate with ellipsis
                }}
              >
                <Link
                  color={"secondary"}
                  href={row.link}
                  target="_blank"
                  onClick={() => handleLinkClick(row.id)}
                >
                  link
                </Link>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => handleToggleFavorite(event, row.id)}
                >
                  {row.favorite ? (
                    <StarIcon sx={{ color: "#f0cb35" }} />
                  ) : (
                    <StarBorderIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FoundVehiclesTable;
