import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function DenseTable({ title, data }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "100%", overflow: "hidden" }}
    >
      <Toolbar>
        <Typography
          sx={{
            fontSize: "30px",
          }}
        >
          {title}
        </Typography>
      </Toolbar>
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.key}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
