import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Alert from "@mui/material/Alert";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Auto Hunt
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  function handleSnackbarClose() {
    navigate("/");
  }

  const validate = () => {
    let isError = false;

    if (email.length === 0) {
      setEmailError("E-mail é obrigatório");
      isError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Endereço de e-mail inválido");
      isError = true;
    } else {
      setEmailError("");
    }

    return isError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      try {
        const response = await axios.post(config.apiUrl + "/forgot-password", {
          email: email,
        });

        // Only navigate if the response is successful
        if (response.status === 200) {
          setOpen(true);
        }
      } catch (error) {}
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          paddingBlock: "8px",
          backgroundColor: "white",
          borderRadius: "20px",
        }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
          <Typography
            sx={{ alignSelf: "flex-start", marginTop: "10px" }}
            component="h1"
            variant="h5"
          >
            Esqueci minha senha
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid>
              <Grid item xs={12} sm={6} sx={{ minWidth: 400 }}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Endereço de e-mail"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => setEmail(event.target.value)}
                  error={emailError.length > 0}
                  helperText={emailError}
                />
              </Grid>
            </Grid>
            {open ? (
              <Alert
                severity="success"
                sx={{ mt: 2 }}
                onClose={handleSnackbarClose}
              >
                Um link para recuperação de senha foi enviado para seu e-mail
              </Alert>
            ) : (
              <>
                {" "}
                <Button
                  color="secondary"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Recuperar senha
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid>
                    <Link href="/signin" variant="body2">
                      Retornar para o login
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 2 }} />
      </Container>
    </div>
  );
}
