import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import RequireNotPaying from "./components/auth/RequireNotPaying";
// import SubscriptionsPage from "./components/subscriptions_page/SubscriptionsPage";
import ResponsiveAppBar from "./components/navbar/AppBar";
import RequireAuth from "./components/auth/RequireAuth";
import RequireNotLoggedIn from "./components/auth/RequireNotLoggedIn";
import SubscriptionsPage from "./pages/MySubscriptionsPage/MySubscriptionsPage";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import SignInSide from "./pages/LoginPage/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import HelpPage from "./pages/HelpPage/HelpPage";
// import PricingPage from "./components/pricing/PricingPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import NotFoundPage from "./components/notfound/NotFoundPage";
import StatisticsPage from "./components/statistics/StatisticsPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import NewLandingPage from "./pages/LandingPage/NewLandingPage";
import OpportunityPage from "./pages/OpportunitiesPage/OpportunitiesPage";
import NewSearchPage from "./pages/NewSearchPage/NewSearchPage";
import { ThemeProvider } from "@mui/material";
import theme from "./themes/theme";
import SubscriptionPage from "./pages/OneSubscriptionPage/SubscriptionPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <ResponsiveAppBar /> */}
        <Routes>
          <Route
            path="/"
            element={
              <RequireNotLoggedIn>
                <NewLandingPage />
              </RequireNotLoggedIn>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path="/createsubscription"
            element={
              <RequireAuth>
                <NewSearchPage />
              </RequireAuth>
            }
          />
          <Route
            path="/subscriptions/:id"
            element={
              <RequireAuth>
                <SubscriptionPage />
              </RequireAuth>
            }
          />
          {/* <Route
            path="/mysubscriptions"
            element={
              <RequireAuth>
                <SubscriptionsPage />
              </RequireAuth>
            }
          /> */}
          <Route path="/signup" element={<SignUpPage />} />
          <Route
            path="/signin"
            element={
              <RequireNotLoggedIn>
                <SignInSide />
              </RequireNotLoggedIn>
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/help"
            element={
              <RequireAuth>
                <HelpPage />
              </RequireAuth>
            }
          />
          {/* <Route
          path="/pricing"
          element={
            <RequireAuth>
              <RequireNotPaying>
                <PricingPage />
              </RequireNotPaying>
            </RequireAuth>
          }
        /> */}
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path="/opportunities"
            element={
              <RequireAuth>
                <OpportunityPage />
              </RequireAuth>
            }
          />
          <Route
            path="/statistics"
            element={
              <RequireAuth>
                <StatisticsPage />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default App;
